import { LoadingButton } from '@mui/lab';
import { StepIconProps, Theme } from '@mui/material';
import Button from '@mui/material/Button';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import React from 'react';

/**
 * Root style for stepper and buttons
 */
const useStyles = makeStyles((t: Theme) => ({
  root: {
    width: '100%',
    marginBottom: '16px',
    marginTop: '20px',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
  },
  stepper: {
    padding: '0 0',
    paddingBottom: '5px',
    backgroundColor: 'transparent',
    justifyContent: 'center',
    [t.breakpoints.down('md')]: {
      paddingBottom: '10px',
    },
  },
  button: {
    '& .MuiButton-label': {
      display: 'block',
    },
  },
  hidden: {
    visibility: 'hidden',
  },
}));

/**
 * Style for individual icons
 */
const CustomIconStyles = makeStyles((t: Theme) => ({
  root: {
    color: '#eaeaf0',
    display: 'flex',
    alignItems: 'center',
  },
  active: {
    color: t.palette.secondary.main,
  },
  circle: {
    width: 25,
    height: 25,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
    [t.breakpoints.down('md')]: {
      width: 20,
      height: 20,
    },
    [t.breakpoints.down('sm')]: {
      width: 15,
      height: 15,
    },
  },
  completed: {
    backgroundColor: t.palette.secondary.main,
  },
}));

/**
 * returns a custom step icon
 */
const CustomStepIcon: React.FC<StepIconProps> = ({ active, completed }) => {
  const classes = CustomIconStyles();

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? <div className={`${classes.circle} ${classes.completed}`} /> : <div className={classes.circle} />}
    </div>
  );
};

interface StepperProps {
  active?: number;
  stepAmount?: number;
  backLabel?: string;
  nextStepLabel?: string;
  lastStepLabel?: string;
  nextLoading?: boolean;
  nextDisabled?: boolean;
  handleNext: () => void;
  handleBack: () => void;
}

/**
 * Renders stepper dots with back and next button.
 *
 * @param active current active state (begins from 0)
 * @param stepAmount how many dots
 * @param handleNext button onClick to next
 * @param handleBack button onClick to go back
 */
const StepperComponent: React.FC<StepperProps> = ({
  active = 0,
  stepAmount = 1,
  backLabel = 'Back',
  nextStepLabel = 'Next',
  lastStepLabel = 'Finish',
  nextLoading = false,
  nextDisabled = false,
  handleNext,
  handleBack,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Button
        onClick={handleBack}
        variant="outlined"
        color="secondary"
        className={active > 0 ? classes.button : `${classes.button} ${classes.hidden}`}
        style={{ justifyContent: 'center', width: '97px' }}
      >
        {backLabel}
      </Button>

      <Stepper className={classes.stepper} activeStep={active} connector={null}>
        {Array.from(Array(stepAmount), (_, i) => (
          <Step key={i}>
            <StepLabel StepIconComponent={CustomStepIcon} />
          </Step>
        ))}
      </Stepper>

      <LoadingButton
        variant="contained"
        color="secondary"
        onClick={handleNext}
        className={classes.button}
        style={{ display: 'flex', justifyContent: 'center', width: '97px' }}
        loading={nextLoading}
        disabled={nextDisabled}
      >
        {active === stepAmount - 1 ? lastStepLabel : nextStepLabel}
      </LoadingButton>
    </div>
  );
};

export default StepperComponent;
